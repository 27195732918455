import Vue from 'vue'
import VueRouter from 'vue-router'
import ClientMonitor from 'skywalking-client-js';


Vue.use(VueRouter)

const routes = [

  {
    path: '/',
    redirect: '/poster',
  },
  {
    path: '/poster/:project_name',
    component: () => import('../views/Poster'),
  },
  {
    path: '/task/:project_name',
    component: () => import('../views/Task'),
  },
  {
    path: '/assist_result/:project_name',
    component: () => import('../views/AssistResult'),
  },
  {
    path: '/tip',
    component: () => import('../views/Tip'),
  },
  {
    path: '/assist_profile/:project_name',
    component: () => import('../views/AssistProfile'),
  },
  {
    path: '/assist_rank/:project_name',
    component: () => import('../views/AssistRank'),
  },
  {
    path: '/sale/:project_name',
    component: () => import('../views/Sale'),
  },
  {
    path: '/income/:project_name',
    component: () => import('../views/Income'),
  },
  {
    path: '/distributor-total',
    component: () => import('../views/DistributorTotal'),
  },
  {
    path: '/distributor-details',
    component: () => import('../views/DistributorDetails'),
  },
]
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
function _isMobile () {
  let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
  return flag;
}
router.beforeEach((to, from, next) => {
  // 当页面访问非poster或tip时，需要在移动端进行访问，否则会自动跳到tip
  if (to.path.indexOf("/poster") !== -1 || to.path === '/tip' || _isMobile()) {
    next();
  } else {
    next({ path: '/tip' })
  }
})
router.afterEach(()=>{
  // 单页面监控
  ClientMonitor.setPerformance({
    collector: 'https://skywalking.ceba.ceshiren.com:11800',
    service: 'wechat-service-frontend',
    serviceVersion: '1.0.0',
    pagePath: location.href,
    useFmp: true
  });
})

export default router
